<template>
  <div :class="$style.info">
    <div v-if="license.isActive">
      <div
        v-if="getSubscriptionCancelDate"
        :class="$style.description"
      >
        Subscription cancels on:
        <span class="has-text-danger">
          {{ (new Date(getSubscriptionCancelDate)).toDateString() }}
        </span>.
      </div>

      <div
        v-else
        :class="$style.description"
      >
        Subscription renewal:
        <span>{{ (new Date(license.expirationDate)).toDateString() }}</span>.
      </div>

      <div
        v-if="!trial && viewDocumentsSpent"
        :class="$style.description"
      >
        {{ nextResetText }}:
        <span>{{ (new Date(license.monthlyDocumentsResetDate)).toDateString() }}</span>.
      </div>

      <div
        v-if="!trial && viewAdditionalDocuments"
        :class="$style.description"
      >
        {{ additionalCreditsText }}:
        <span>{{ license.additionalCredits }}</span>
      </div>

      <template v-if="viewDocumentsSpent">
        <div :class="$style.description">
          {{ spentText }}:
          <span>{{ documentsSpent }} of {{ license.creditsLimit }}</span>
        </div>

        <div :class="$style.progress">
          <div :class="$style.line">
            <div
              :class="$style.inner"
              :style="{ width: `${usedCreditsPercent}%` }"
            />
          </div>

          <div :class="$style.label">
            {{ usedCreditsPercent }}%
          </div>
        </div>
      </template>
    </div>

    <div
      v-else
      :class="$style.description"
    >
      Subscription has expired. Start a new subscription below.
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useSubscriptionsStore } from '@/stores/index.js';

export default {
  name: 'InfoSection',
  props: {
    itemsName: {
      type: String,
      default: '',
    },
    license: {
      type: Object,
      default: () => ({}),
    },
    trial: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useSubscriptionsStore, ['getSubscriptionCancelDate']),

    usedCreditsPercent() {
      if (this.documentsSpent <= 0) {
        return 0;
      }

      return Math.floor(this.documentsSpent / (this.license.creditsLimit / 100));
    },
    documentsSpent() {
      const spent = this.license.creditsLimit - this.license.credits;

      return spent && spent > 0 ? spent : 0;
    },
    viewDocumentsSpent() {
      return !(this.viewAdditionalDocuments && this.license.creditsLimit === 0 && this.license.plan === 'Custom');
    },
    viewAdditionalDocuments() {
      return this.license.additionalCredits > 0 || this.license.creditsLimit <= 0;
    },
    additionalCreditsText() {
      if (this.license.plan === 'Custom' && this.license.creditsLimit === 0) {
        return `${this.itemsName} left`;
      }

      return `Additional ${this.itemsName} left`;
    },
    nextResetText() {
      return `Next monthly ${this.itemsName} reset`;
    },
    spentText() {
      return `${this.itemsName} spent`;
    },
  },
};
</script>

<style lang="scss" module>
.info {
  position: relative;

  .description {
    font-size: 16px;
    color: var(--color-main);

    &::first-letter {
      text-transform: uppercase;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    span {
      font-weight: 600;
    }
  }

  .progress {
    max-width: 300px;

    .line {
      height: 4px;
      background-color: var(--color-gray-light);

      .inner {
        width: 0;
        height: 100%;
        background-color: var(--current-color);
      }
    }

    .label {
      font-size: 12px;
      text-align: right;
      color: var(--current-color);
    }
  }
}
</style>
