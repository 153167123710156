<template>
  <div
    :class="$style.planSwitcher"
    @click="togglePlan"
  >
    <button :class="[$style.button, value === 'annual' && $style['button--active']]">
      Annual
    </button>

    <button :class="[$style.button, value === 'monthly' && $style['button--active']]">
      Monthly
    </button>
  </div>
</template>

<script>
export default {
  name: 'PlanSwitcher',
  props: {
    value: {
      type: String,
      default: 'annual',
    },
  },
  methods: {
    togglePlan() {
      return this.$emit('input', this.value === 'annual' ? 'monthly' : 'annual');
    },
  },
};
</script>

<style lang="scss" module>
.planSwitcher {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  user-select: none;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    padding: 8px 35px;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-secondary);
    background-color: #f5f1fb;
    transition-duration: var(--transition-duration);
    cursor: pointer;

    &:last-child {
      margin-left: -30px;
    }

    &--active {
      position: relative;
      z-index: 1;
      color: var(--color-white);
      background-color: var(--current-color);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
