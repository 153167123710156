<template>
  <div
    :class="$style.modal"
    @wheel.prevent="wheel($event)"
  >
    <form @submit.prevent="submit">
      <div :class="$style.body">
        <div :class="[$style.handle, 'modal-handler']" />

        <div :class="$style.title">
          <span>${{ plan.price * value }}</span>
          / {{ `${rangeValue} ${units}` }}
        </div>

        <input-range
          :class="$style.range"
          :title="`Number of ${units}`"
          :value.sync="rangeValue"
          :min="step"
          :max="step * maxValue"
        />

        <b-button
          :class="$style.btn"
          type="is-primary"
          native-type="submit"
        >
          Checkout
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import InputRange from '@/components/basic/input-range/index.vue';

export default {
  name: 'Quantity',
  components: { InputRange },
  props: {
    plan: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: String,
      default: '',
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      value: 1,
      maxValue: 200,
      timeOut: null,
      interval: null,
      isHolded: false,
    };
  },

  computed: {
    units() {
      return this.product === 'documents' ? 'documents' : 'executions';
    },

    step() {
      return this.product === 'documents' ? 100 : 500;
    },

    rangeValue: {
      get() {
        return this.value * this.step;
      },

      set(value) {
        this.value = value / this.step;
      },
    },
  },

  created() {
    document.addEventListener('keydown', this.keyDown);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDown);
  },

  methods: {
    changeValue({ isIncrement = false, isDecrement = false }) {
      if (isIncrement && this.value < this.maxValue) {
        this.value += 1;
      }
      if (isDecrement && this.value > 1) {
        this.value -= 1;
      }
    },

    keyDown(event) {
      this.changeValue({
        isIncrement: event.key === '+' || event.key === 'ArrowRight',
        isDecrement: event.key === '-' || event.key === 'ArrowLeft',
      });
    },

    wheel(event) {
      this.changeValue({ isIncrement: event.deltaY < 0, isDecrement: event.deltaY > 0 });
    },

    submit() {
      this.$emit('close');
      this.onSubmit(this.value);
    },
  },
};
</script>

<style lang="scss" module>
.body {
    position: relative;
    padding: 26px 45px 16px;
    display: flex;
    flex-direction: column;
  }

  .handle {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 60px;
}

.title {
  margin-bottom: 22px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  span {
    font-weight: 700;
    font-size: 24px;
  }
}
.range {
  margin-bottom: 74px;
}

.btn {
  align-self: center;
  min-width: 190px;
}
</style>
