<template>
  <div
    :class="[
      $style.subscription,
      { [$style['subscription--cards']]: showCards },
    ]"
  >
    <modal
      name="loading"
      :width="100"
      height="auto"
      :styles="{ background: 'transparent', boxShadow: 'none' }"
    >
      <spinner
        :size="100"
        :width="10"
        :duration="700"
      />
    </modal>

    <div v-if="getLicensePlan">
      <RenewalMessage
        v-if="showRenewalMessage && getIs2co && getOwnTeamSelected"
        :product-name="getProduct"
        :class="$style.field"
        @input="toggleRenewal(true)"
      />

      <Controls
        v-if="showControls"
        :items-name="itemsName"
        :show-renew="getIs2co && getLicenseIsActive"
        :show-more="getLicenseIsActive"
        :show-upgrade="!(['Cabinet', 'Whale']
          .includes(getLicensePlan) && license.isAnnual) && getLicenseIsActive"
        :show-cancel="!getIs2co && !getErrorStatusSubscription && !isCanceled && getLicenseIsActive"
        :show-resume="!getIs2co && isCanceled && getLicenseIsActive"
        :class="$style.field"
        @input="onControls"
      />

      <Info
        :items-name="itemsName"
        :license="license"
        :trial="getIsTrialLicense"
        :class="$style.field"
      />

      <div
        v-if="activePayedPlan && getOwnTeamSelected"
        :class="$style.settings"
      >
        <b-switch
          v-if="identicalEmails && getIs2co"
          :value="isRenewal"
          :class="$style.item"
          @input="toggleRenewal"
        >
          <div :class="$style.text">
            Subscription auto-renewal is {{ isRenewal ? 'enabled' : 'disabled' }}
          </div>
        </b-switch>

        <b-switch
          :value="emailNotification"
          :class="$style.item"
          @input="toggleNotification"
        >
          <div :class="$style.text">
            Email notifications on {{ itemsName }} limit are
            {{ emailNotification ? 'enabled' : 'disabled' }}
          </div>
        </b-switch>
      </div>
    </div>

    <template v-if="loaded">
      <div v-if="!getLicensePlan">
        <div :class="$style.description">
          Start using Plumsail {{ productName }} to activate your free 30-day trial
        </div>
      </div>

      <div
        v-if="showCards"
        :class="$style.cards"
      >
        <div :class="$style.save">
          Save up to 16% with annual plan
        </div>

        <PlanSwitcher v-model="planType" />

        <div :class="$style.items">
          <PlanItem
            v-for="item in productPlanList.filter(({ name }) => name !== 'More')"
            :key="item.id"
            :data="item"
            :type="planType"
            :product-name="getProduct"
            @click="checkout(item)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia';
import dayjs from 'dayjs';
import Confirm from '@/components/modals/confirm.vue';
import Alert from '@/components/modals/alert.vue';
import Spinner from '@/components/spinner.vue';
import RenewalMessage from './components/renewal-message.vue';
import Controls from './components/controls.vue';
import Info from './components/info.vue';
import PlanSwitcher from './components/plan-switcher.vue';
import PlanItem from './components/plan-item.vue';
import BillingModal from './components/modals/billing-notification-modal.vue';
import QuantityModal from './components/modals/quantity-modal.vue';
import upgradeModal from './components/modals/upgrade-modal.vue';
import { openStripe } from './payment.js';
import {
  useHelpStore,
  useSubscriptionsStore,
  useAppStore,
  useTeamsStore,
  useSettingsStore,
} from '@/stores/index.js';

export default {
  name: 'Subscription',
  components: {
    RenewalMessage,
    Controls,
    Info,
    PlanSwitcher,
    PlanItem,
    Spinner,
  },
  data() {
    return {
      planType: 'annual',
      optionsModal: {
        clickToClose: false, width: '455px', height: 'auto', adaptive: true,
      },
      showLoaderTimeout: null,
    };
  },
  computed: {
    ...mapState(useAppStore, ['getProduct']),
    ...mapState(useSettingsStore, ['getProfile']),
    ...mapState(useTeamsStore, ['getOwnTeamSelected']),
    ...mapState(useSubscriptionsStore, [
      'getLicenseId',
      'getLicensePlan',
      'getLicenseIsActive',
      'getIs2co',
      'getIsTrialLicense',
      'getIsTestOrPartner',
      'getBillingUrl',
      'getStatusSubscription',
      'getErrorStatusSubscription',
      'renewalLink',
      'isRenewal',
      'billingEmail',
      'emailNotification',
      'isCancelSuccess',
      'isResumeSuccess',
      'planList',
    ]),

    ...mapWritableState(useSubscriptionsStore, ['loaded', 'license']),

    itemsName() {
      if (this.getProduct === 'documents') return 'documents';
      if (this.getProduct === 'actions') return 'executions';

      return '';
    },
    productPlanList() {
      return this.planList.data[this.getProduct] || [];
    },
    isLoadingPalnList() {
      return this.planList.loading && this.showCards;
    },
    email() {
      return this.getProfile.email;
    },
    subscriptionCanBeRenew() {
      return dayjs().diff(dayjs(this.license?.expirationDate), 'days') <= 5;
    },
    payedPlan() {
      return this.getLicensePlan && !this.getIsTrialLicense && !this.getIsTestOrPartner;
    },
    activePayedPlan() {
      return this.payedPlan && this.getLicenseIsActive;
    },
    identicalEmails() {
      return this.billingEmail === this.email;
    },
    showRenewalMessage() {
      return this.payedPlan && this.renewalLink && !this.isRenewal
        && this.identicalEmails && this.getLicenseIsActive;
    },
    showCards() {
      return (!this.getLicensePlan || this.getIsTrialLicense || !this.getLicenseIsActive)
        && this.getOwnTeamSelected;
    },
    showControls() {
      return this.payedPlan && this.getOwnTeamSelected;
    },
    isCanceled() {
      return this.getStatusSubscription && this.getStatusSubscription.status === 3;
    },
    productName() {
      if (!this.getProduct) return '';
      return this.getProduct[0].toUpperCase() + this.getProduct.slice(1);
    },
  },

  watch: {
    isLoadingPalnList(value) {
      if (value) {
        this.showLoaderTimeout = setTimeout(() => { this.$modal.show('loading'); }, 700);
      } else {
        clearTimeout(this.showLoaderTimeout);
        this.$modal.hide('loading');
        this.showLoaderTimeout = null;
      }
    },
  },

  async created() {
    this.loadPlanList(this.getProduct);
    await this.loadLicense({ product: this.getProduct });

    if (!this.getIs2co && this.getLicenseIsActive) {
      await this.loadStatusSubscription();
    }

    if (this.getProduct === 'documents') this.setHelpOpen(true);

    if (this.payedPlan) {
      this.loadLicenseRenewal();
      this.loadSubscription();
    }

    if (this.activePayedPlan) {
      this.loadEmailNotification({ product: this.getProduct });
    }
  },

  beforeDestroy() {
    this.setHelpOpen(false);
    this.license = null;
    this.loaded = false;
    this.clearStatusSubscription();
    this.clearUpgradePlans();
  },

  methods: {
    ...mapActions(useHelpStore, { setHelpOpen: 'setOpen' }),
    ...mapActions(useSubscriptionsStore, [
      'clearStatusSubscription',
      'clearUpgradePlans',
      'loadLicense',
      'loadLicenseRenewal',
      'loadSubscription',
      'updateLicenseRenewal',
      'loadEmailNotification',
      'updateEmailNotification',
      'loadStatusSubscription',
      'cancelSubscription',
      'resumeSubscription',
      'loadPlanList',
      'getBillingPortalUrl',
    ]),

    toggleRenewal(value) {
      if (this.identicalEmails) {
        return this.$modal.show(
          Confirm,
          {
            message: `Are you sure you want to ${this.isRenewal ? 'disabled' : 'enabled'}
                      automatic subscription renewal?`,
            onSubmit: async () => {
              const { error } = await this.updateLicenseRenewal({ value });

              if (error) {
                this.$modal.show(
                  Alert,
                  {
                    message: 'Error enabling automatic subscription renewal, please contact support',
                  },
                  { ...this.optionsModal, draggable: false },
                );
              }
            },
          },
          { ...this.optionsModal, draggable: '.modal-handler' },
        );
      }

      return this.showBillingModal();
    },

    async toggleNotification(value) {
      const { error } = await this.updateEmailNotification({
        product: this.getProduct,
        value,
      });

      if (error) {
        this.$modal.show(
          Alert,
          {
            message: `Error ${this.emailNotification ? 'enabling' : 'disabling'} notifications, please contact support`,
          },
          { ...this.optionsModal, draggable: false },
        );
      }
    },

    onControls(name) {
      return this[name]?.();
    },

    renew() {
      if (this.subscriptionCanBeRenew) window.open(this.renewalLink, '_blank');
    },

    upgrade() {
      if (this.getIs2co) {
        window.open(`https://secure.2checkout.com/order/upgrade.php?LICENSE=${this.getLicenseId}`, '_blank');
      } else {
        const optionsModal = {
          ...this.optionsModal,
          classes: '',
          width: '450px',
          draggable: '.modal-handler',
        };
        this.$modal.show(upgradeModal, {}, optionsModal);
      }
    },

    more() {
      const plan = this.productPlanList.find(({ name }) => name === 'More');

      return this.$modal.show(
        QuantityModal,
        {
          plan,
          product: this.getProduct,
          onSubmit: async (quantity) => {
            const showLoaderTimeout = setTimeout(() => { this.$modal.show('loading'); }, 700);

            await openStripe({
              data: plan,
              quantity,
              email: this.email,
              billingEmail: this.billingEmail,
            });

            clearTimeout(showLoaderTimeout);
            this.$modal.hide('loading');
          },
        },
        {
          clickToClose: true, width: '800px', height: 'auto', draggable: '.modal-handler', adaptive: true,
        },
      );
    },

    billing() {
      if (this.identicalEmails) return this.goBilling();

      return this.showBillingModal();
    },

    async checkout(data) {
      if (data.name === 'Personal') {
        window.open('https://plumsail.com/documents/store/custom-pricing/', '_blank');
        return;
      }
      const showLoaderTimeout = setTimeout(() => { this.$modal.show('loading'); }, 700);

      await openStripe({
        data,
        planType: this.planType,
        email: this.email,
        billingEmail: this.billingEmail,
      });

      clearTimeout(showLoaderTimeout);
      this.$modal.hide('loading');
    },

    async goBilling() {
      const data = await this.getBillingPortalUrl({ product: this.getProduct });
      const url = data.isUrl ? data.content : this.getBillingUrl;
      window.open(url, '_blank');
    },

    showBillingModal() {
      this.$modal.show(
        BillingModal,
        {
          email: this.billingEmail,
          goBilling: () => { window.open(this.getBillingUrl, '_blank'); },
        },
        { ...this.optionsModal, draggable: '.modal-handler' },
      );
    },

    cancel() {
      this.$modal.show(
        Confirm,
        {
          title: 'Cancel subscription?',
          message: 'Your subscription will be active until the end of the current billing period.',
          submitBtnType: 'is-danger',
          submitBtnText: 'Confirm',
          closeBtnText: 'Cancel',
          handlerStyle: 'modal-handler',
          showCloseBtn: true,
          onSubmit: async () => {
            this.clearStatusSubscription();
            await this.cancelSubscription();
            if (this.isCancelSuccess) {
              this.loadStatusSubscription();
            } else {
              this.$modal.show(
                Alert,
                { title: 'Cancel subsriptuion failed' },
                { ...this.optionsModal, draggable: false },
              );
              this.loadStatusSubscription();
            }
          },
        },
        { ...this.optionsModal, draggable: '.modal-handler' },
      );
    },

    resume() {
      this.$modal.show(
        Confirm,
        {
          title: 'Resume subscription?',
          message: 'Your subscription will be resumed. We will renew it automatically at the end of the billing period.',
          submitBtnText: 'Confirm',
          closeBtnText: 'Cancel',
          showCloseBtn: true,
          onSubmit: async () => {
            this.clearStatusSubscription();
            await this.resumeSubscription();
            if (this.isResumeSuccess) {
              this.loadStatusSubscription();
            } else {
              this.$modal.show(
                Alert,
                { title: 'Resume subsriptuion failed' },
                { ...this.optionsModal, draggable: false },
              );
              this.loadStatusSubscription();
            }
          },
        },
        { ...this.optionsModal, draggable: '.modal-handler' },
      );
    },
  },
};
</script>

<style lang="scss" module>
// TODO: if we not open 2co remove this
:global(.two-checkout) {
  overflow: auto;
}

.subscription {
  .description {
    margin-bottom: 25px;
    font-size: 16px;
    color: var(--color-main);
  }

  .field:not(:last-child) {
    margin-bottom: 30px;
  }

  .settings {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    .item {
      display: flex;
      color: var(--current-color);

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .cards {
    .save {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: var(--color-main);
    }

    .items {
      display: flex;
      flex-flow: row wrap;
      gap: 40px;
      padding-top: 20px;
    }
  }
}
@media (max-width: 768px) {
  .subscription--cards, .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .save {
    text-align: center;
  }

  .items {
    justify-content: center;
  }
}
</style>
