<template>
  <alert
    btn-text="Close"
    :title="title"
    @close="$emit('close')"
  >
    <span v-if="status === 0">
      Your payment has been processed successfully.
      The plan will be updated shortly. If you won’t
      see any changes after refreshing the page in an hour, contact our

      <a
        href="https://plumsail.com/support/"
        target="_blank"
      >
        support team.
      </a>
    </span>

    <span v-if="status === 1">
      We sent an invoice to your billing address:
      <a :href="`mailto:${email}`">{{ email }}</a>.
      Once it is paid, your plan will be upgraded.
    </span>

    <span v-if="status === 2">
      Unable to process the payment.
      Please navigate to the
      <a
        href="https://plumsail.com/store/billing/"
        target="_blank"
      >
        billing portal
      </a>
      and verify your payment method.
    </span>
  </alert>
</template>
<script>
import Alert from '@/components/modals/alert.vue';

export default {
  name: 'UpgradeAlert',
  components: { Alert },
  props: {
    status: { type: Number, default: 0 },
    email: { type: [String, null], default: '' },
  },

  computed: {
    title() {
      switch (this.status) {
        case 1:
          return 'Invoice sent';
        case 2:
          return 'Payment failed';
        default:
          return 'Payment succeeded';
      }
    },
  },
};
</script>
