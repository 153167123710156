<template>
  <ul :class="[{ [ $style.managePanel_is2co]: getIs2co }, $style.managePanel]">
    <li
      v-if="showRenew"
      :class="$style.item"
    >
      <button
        type="button"
        title="Renew subscription"
        :class="$style.button"
        @click="$emit('input', 'renew')"
      >
        <font-awesome-icon
          :icon="['fal', 'rotate-right']"
          :class="$style.icon"
        />
        Renew
      </button>
    </li>

    <li
      v-if="showUpgrade"
      :class="$style.item"
    >
      <button
        type="button"
        title="Switch to another plan"
        :class="$style.button"
        @click="$emit('input', 'upgrade')"
      >
        <font-awesome-icon
          :icon="['fal', 'flask']"
          :class="$style.icon"
        />
        Upgrade
      </button>
    </li>

    <li
      v-if="showMore"
      :class="$style.item"
    >
      <button
        type="button"
        title="Get additional documents"
        :class="$style.button"
        :disabled="isLoadingPlans"
        @click="$emit('input', 'more')"
      >
        <font-awesome-icon
          :icon="['fal', 'folder-plus']"
          :class="$style.icon"
        />
        More {{ itemsName }}
      </button>
    </li>

    <li :class="$style.item">
      <button
        type="button"
        title="Billing management"
        :class="$style.button"
        @click="$emit('input', 'billing')"
      >
        <font-awesome-icon
          :icon="['fal', 'credit-card']"
          :class="$style.icon"
        />
        Billing portal
      </button>
    </li>

    <li
      v-if="showCancel"
      :class="$style.item"
    >
      <button
        type="button"
        title="Cancel subscription"
        :disabled="!getStatusSubscription"
        :class="$style.button"
        @click="$emit('input', 'cancel')"
      >
        <font-awesome-icon
          :icon="['fal', 'ban']"
          :class="$style.icon"
        />
        Cancel
      </button>
    </li>

    <li
      v-if="showResume"
      :class="$style.item"
    >
      <button
        type="button"
        title="Resume subscription"
        :disabled="!getStatusSubscription"
        :class="$style.button"
        @click="$emit('input', 'resume')"
      >
        <font-awesome-icon
          :icon="['fal', 'rotate-left']"
          :class="$style.icon"
        />
        Resume
      </button>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'pinia';
import { useSubscriptionsStore } from '@/stores/index.js';

export default {
  name: 'Controls',
  props: {
    itemsName: {
      type: String,
      default: '',
    },
    showRenew: {
      type: Boolean,
      default: false,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    showUpgrade: {
      type: Boolean,
      default: false,
    },
    showResume: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useSubscriptionsStore, ['getIs2co', 'getStatusSubscription', 'planList']),
    isLoadingPlans() {
      return this.planList.loading;
    },
  },
};
</script>

<style lang="scss" module>
.managePanel {
  display: flex;
  gap: 35px;

  &_is2co {
    color: var(--current-color);
  }

  .button {
    color: inherit;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    font-size: inherit;
    line-height: 1;
    transition-property: opacity;
    transition-duration: var(--transition-duration);
    cursor: pointer;

    .icon {
      margin-right: 5px;
    }

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

@media (max-width: 576px) {
  .managePanel {
    gap: 15px;
    flex-direction: column;
  }
}
</style>
