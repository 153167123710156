import Vue from 'vue';
import axios from 'axios';
import { checkFirstPromoterReferral, getFirstPromoterId, getGAClientId, getGASessionId } from '@/utils/index.js';
import { stripePlumsailApi, stripeBrooklimeApi } from '@/utils/url-manager.js';

const axiosInstance = axios.create();

const state = Vue.observable({ loaded: false, lib: null });

export const init2co = () => new Promise((resolve) => {
  if (!state.lib) {
    const script = document.createElement('script');

    script.setAttribute('src', 'https://secure.avangate.com/checkout/client/twoCoInlineCart.js');
    document.head.appendChild(script);

    script.onload = () => {
      const lib = window.TwoCoInlineCart;

      state.lib = lib;

      lib.setup.setMerchant('FUZORLLC');
      lib.register();

      // removeAttribute here because 2checkout set overflow auto to body tag
      // add bug page scroll

      document.body.removeAttribute('style');

      lib.events.subscribe('cart:closed', () => document.body.removeAttribute('style'));

      // this listeners to fix 2checkout inner css bug scroll
      lib.events.subscribe('cart:opened', () => document.documentElement.classList.add('two-checkout'));
      lib.events.subscribe('cart:closed', () => document.documentElement.classList.remove('two-checkout'));

      state.loaded = true;
      resolve();
    };

    return;
  }

  resolve();
});

export const open2co = async ({
  data,
  email,
  billingEmail,
  id,
  quantity,
}) => {
  await init2co();

  const { lib, loaded } = state;

  if (!loaded) return;

  lib.products.removeAll();
  lib.billing.setEmail(billingEmail);

  lib.products.add({
    code: data.twoCo,
    quantity,
    additionalFields: [
      {
        code: 'AccountEmail',
        value: billingEmail || email,
      },
    ],
  });

  lib.cart.setExternalCustomerReference(id);
  lib.cart.checkout();
};

export const openStripe = async ({
  data: { monthly, annual },
  planType,
  email,
  billingEmail,
  quantity,
}) => {  
  const tid = getFirstPromoterId();
  const gaCid = getGAClientId();
  const gaSid = getGASessionId();

  const isFirstPromoterReferral = checkFirstPromoterReferral();
  const api = isFirstPromoterReferral
    ? stripePlumsailApi
    : stripeBrooklimeApi;

  let metadata = { Account: email, ga_session_id: gaSid, ga_client_id: gaCid };

  const payload = {
    price: planType === 'annual' ? annual : monthly,
    email: billingEmail || email,
    successUrl: window.location.href,
    cancelUrl: window.location.href,
    quantity,
    metadata: isFirstPromoterReferral
      ? { ...metadata, fp_tid: tid }
      : metadata,
  };

  const { data } = await axiosInstance.post(`${api}checkout`, payload);
  return (window.location.href = data.url);
};
