<template>
  <div
    ref="modal"
    :class="$style.modal"
    tabindex="0"
    @keydown.enter="submit"
  >
    <div :class="[$style.header, 'modal-handler']">
      <div :class="$style.title">
        Email address:
        <a :href="`mailto:${email}`">{{ email }}</a>
      </div>
    </div>

    <div :class="$style.body">
      Your subscription was purchased using a different email address.
      Please use it to manage billing.
    </div>

    <div :class="$style.footer">
      <b-button
        type="is-primary"
        :class="$style.footer__btn"
        @click="submit"
      >
        Go to billing
      </b-button>

      <b-button
        type="is-light"
        :class="$style.footer__btn"
        @click="$emit('close')"
      >
        Close
      </b-button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BillingNotificationModal',
  props: {
    goBilling: {
      type: Function,
      required: true,
    },

    email: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.modal?.focus());
  },
  methods: {
    submit() {
      this.goBilling();
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" module>
  .modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;

    &:focus-visible {
      outline: none;
    }

    &--headless {
      .header {
        display: none;
      }
    }
  }
  .header {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    word-wrap: break-word;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .footer {
    padding: 9px 8px 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);

    .footer__btn {
      font-weight: 400;
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
</style>
