<template>
  <div
    :class="[
      $style.planItem,
      $style[`planItem--${productName}`],
      data.popular && $style['planItem--popular'],
    ]"
  >
    <div :class="$style.popular">
      Most popular
    </div>

    <div :class="$style.image">
      <img
        :src="require(`@/assets/store/${productName}/${data.name.toLowerCase()}.${imgExtension}`)"
        :alt="data.name"
      >
    </div>

    <div :class="$style.name">
      {{ data.name }}
    </div>

    <div
      v-if="data.description"
      :class="$style.description"
    >
      {{ data.description }}
    </div>

    <div
      v-if="data.additionalDescription"
      :class="$style.additionalDescription"
    >
      {{ data.additionalDescription }}
    </div>

    <template v-if="data.name.toLowerCase() === 'personal'">
      <b-button
        type="is-primary"
        expanded
        :class="$style.button"
        @click="$emit('click')"
      >
        Contact us
      </b-button>
    </template>

    <template v-else>
      <div :class="$style.price">
        ${{ data[`${type}Price`] }} <span>/ Month</span>
      </div>

      <div :class="$style.executions">
        {{ data.executions }} {{ executionsName }} monthly
      </div>

      <div :class="$style.sla">
        {{ data.sla ? 'SLA' : 'no SLA' }}
      </div>

      <b-button
        type="is-primary"
        expanded
        :class="$style.button"
        @click="$emit('click')"
      >
        Buy
      </b-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PlanItem',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    productName: {
      type: String,
      default: '',
    },
  },
  computed: {
    executionsName() {
      return this.productName === 'documents' ? 'documents' : 'executions';
    },
    imgExtension() {
      return this.productName === 'documents' ? 'svg' : 'png';
    },
  },
};
</script>

<style lang="scss" module>
.planItem {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 5px;
  padding: 165px 30px 35px;
  width: 250px;

  .popular {
    position: absolute;
    top: 30px;
    display: none;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--color-secondary);
  }

  .image {
    position: absolute;
    top: 60px;
    width: 25%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-secondary);
  }

  .description,
  .additionalDescription {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--color-main);
  }

  .additionalDescription {
    margin-top: 55px;
    margin-bottom: 45px;
  }

  .price {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: var(--color-secondary);

    span {
      font-weight: 400;
      font-size: 18px;
    }
  }

  .executions {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 16px;
    color: var(--color-main);
  }

  .sla {
    margin-bottom: 40px;
    font-size: 16px;
    color: var(--color-main);
  }

  .button {
    margin-top: auto;
  }

  &--actions {
    .image {
      top: 70px;
      width: auto;
    }
  }

  &--popular {
    background-color: #f5f1fb;

    .popular {
      display: block;
    }
  }
}
</style>
