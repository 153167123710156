<template>
  <div v-if="!isClosed">
    <div :class="$style.bar">
      <div :class="$style.line" />

      <div :class="$style.text">
        <span>
          We recommended you

          <a @click="$emit('input')">
            enable subscription auto-renewal
          </a>

          to avoid service interruption.
        </span>
      </div>

      <a
        :class="$style.close"
        @click="close"
      >
        <font-awesome-icon :icon="['fal', 'xmark']" />
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RenewalMessage',
  props: {
    productName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isClosed: localStorage.getItem(`renewalIsClosed-${this.productName}`) === 'true',
    };
  },
  methods: {
    close() {
      this.isClosed = true;
      localStorage.setItem(`renewalIsClosed-${this.productName}`, 'true');
    },
  },
};
</script>

<style lang="scss" module>
.bar {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 2px 0% 0% 2px;
  min-height: 50px;
  background-color: var(--color-alabaster);

  .line {
    align-self: stretch;
    width: 5px;
    background-color: var(--current-color);
  }

  .text {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    a {
      color: var(--current-color);
    }
  }

  .close {
    position: absolute;
    right: 15px;
    top: 50%;
    color: var(--color-main);
    transform: translateY(-50%);
    transition-duration: var(--transition-duration);

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
