<template>
  <div :class="$style.modal">
    <div
      v-if="upgradePlans.loading"
      :class="$style['loader-wrapper']"
    >
      <spinner
        :size="100"
        :width="10"
        :duration="700"
      />
    </div>

    <div v-show="!upgradePlans.loading">
      <div :class="[$style.header, 'modal-handler']">
        <div
          v-if="upgradePlans.error"
          :class="$style.title"
        >
          Upgrade failed
        </div>

        <template v-if="getUpgradePlans">
          <div
            v-if="!getUpgradePlans.monthly"
            :class="$style.title"
          >
            Annual plan
          </div>

          <div
            v-else
            :class="$style['switcher-wrapper']"
          >
            <plan-switcher v-model="planType" />
          </div>
        </template>
      </div>

      <div :class="$style.body">
        <ul
          v-if="getUpgradePlans"
          :class="$style.list"
        >
          <li
            v-for="(item, index) of getUpgradePlans[planType]"
            :key="index"
            :class="$style.list__item"
          >
            <label :class="$style.plan">
              <div :class="[$style.plan__btn, $style['radio-btn']]">
                <input
                  v-model="currentPlanId"
                  :class="$style['radio-btn__default']"
                  type="radio"
                  name="plan"
                  :value="item.id"
                >
                <span :class="$style['radio-btn__custom']" />
              </div>

              <div
                :class="[$style.plan__img, { [$style['plan__img--documents']]: isDocuments }]"
              >
                <img
                  :src="require(
                    `@/assets/store/${getProduct}/${item.plan.toLowerCase()}.${imgExtension}`,
                  )"
                  :alt="item.plan"
                >
              </div>

              <span :class="$style.plan__name">
                {{ item.plan }}
              </span>

              <div :class="[$style.price, $style.plan__price]">
                <span :class="$style.price__val">
                  {{ currencySymbol + item.price }}
                </span>
                <span :class="$style.price__period">
                  / month
                </span>
              </div>
            </label>
          </li>
        </ul>

        <div :class="$style['total-price']">
          <div
            v-if="upgradePrice.loading"
            :class="$style['total-price__loader']"
          >
            <spinner
              :size="30"
              :width="3"
              :duration="700"
            />
          </div>
          <table v-if="totalPrice">
            <tr>
              <td :class="$style['total-price__cell']">
                Tax:
              </td>
              <td :class="$style['total-price__cell']">
                {{ currencySymbol + totalPrice.tax }}
              </td>
            </tr>
            <tr>
              <td :class="$style['total-price__cell']">
                Pay now:
              </td>
              <td :class="$style['total-price__cell']">
                {{ currencySymbol + totalPrice.total }}
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="upgradePlans.error"
          :class="$style['error-block']"
        >
          Please try upgrading your plan later or contact our
          <a
            href="https://plumsail.com/support/"
            target="_blank"
            rel="noopener noreferrer"
          >
            support team
          </a>.
        </div>
      </div>

      <div :class="$style.footer">
        <b-button
          v-if="!upgradePlans.error"
          :disabled="(!totalPrice || !totalPrice.total) && !upgradePrice.error"
          type="is-primary"
          :class="[$style.footer__btn, { ['is-loading']: upgradeStatus.loading }]"
          @click="update"
        >
          Upgrade
        </b-button>

        <b-button
          type="is-light"
          :class="$style.footer__btn"
          @click="$emit('close')"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import getSymbolFromCurrency from 'currency-symbol-map';
import { mapState, mapActions } from 'pinia';
import { useSubscriptionsStore, useAppStore } from '@/stores/index.js';
import PlanSwitcher from '../plan-switcher.vue';
import Spinner from '@/components/spinner.vue';
import UpgradeAlert from './upgrade-alert.vue';

export default {
  name: 'UpgradeModal',
  components: { PlanSwitcher, Spinner },

  data() {
    return {
      planType: 'annual',
      currentPlanId: '',
    };
  },

  computed: {
    ...mapState(useAppStore, ['getProduct']),
    ...mapState(useSubscriptionsStore, ['upgradePlans', 'upgradePrice', 'currency', 'upgradeStatus']),

    imgExtension() {
      return this.isDocuments ? 'svg' : 'png';
    },

    currencySymbol() {
      return getSymbolFromCurrency(this.currency);
    },

    getUpgradePlans() {
      if (!this.upgradePlans.data) return null;

      const [...data] = this.upgradePlans.data;

      return data
        .sort((a, b) => a.price - b.price)
        .reduce((acc, value) => {
          const period = value.period.toLowerCase();
          let item = null;

          if (period === 'annual') item = { ...value, price: (value.price / 12).toFixed(2) };
          else item = { ...value };

          return {
            ...acc,
            [period]: acc[period] ? [...acc[period], item] : [item],
          };
        }, {});
    },

    isDocuments() {
      return this.getProduct.toLowerCase() === 'documents';
    },

    totalPrice() {
      if (this.upgradePrice.data) {
        const { data } = this.upgradePrice;
        return {
          ...data,
          subtotal: parseFloat(data.subtotal / 100).toFixed(2),
          tax: parseFloat(data.tax / 100).toFixed(2),
          total: parseFloat(data.total / 100).toFixed(2),
        };
      }
      return null;
    },
  },

  watch: {
    currentPlanId(value) {
      this.loadUpgradePrice({ priceId: value });
    },
  },

  mounted() {
    if (!this.upgradePlans.data) this.loadUpgradePlans();
    this.clearUpgradePrice();
  },

  methods: {
    ...mapActions(useSubscriptionsStore, [
      'clearUpgradePrice',
      'clearUpgradePlans',
      'loadUpgradePlans',
      'loadUpgradePrice',
      'upgradePlan',
      'loadLicense',
    ]),

    async update() {
      await this.upgradePlan({ priceId: this.currentPlanId });
      const { data } = this.upgradeStatus;

      const showModal = (modalProps) => {
        this.$modal.show(
          UpgradeAlert,
          modalProps,
          {
            clickToClose: false, width: '450px', height: 'auto', draggable: '.modal-handler', adaptive: true,
          },
        );

        this.$emit('close');
      };

      if (this.upgradeStatus.error) {
        showModal({ status: 2 });
        return;
      }

      if (data && data.status === 0) {
        this.loadLicense({ product: this.getProduct });
        this.clearUpgradePlans();
      }

      showModal(data);
    },
  },
};
</script>

<style lang="scss" module>
  .modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    .header {
      display: flex;
      padding: 10px 20px;
      border-bottom: none;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      background-color: var(--color-white);
    }

    .title {
      color: hsl(0deg, 0%, 21%);
      flex-grow: 1;
      flex-shrink: 0;
      margin-right: auto;
      font-weight: 600;
      font-size: 17px;
      line-height: 27px;
      word-break: break-all;
    }

    .body {
      display: flex;
      flex-direction: column;
      padding: 10px 0 26px;
      background-color: var(--color-white);
    }

    .footer {
      padding: 9px 8px 10px;
      display: flex;
      justify-content: flex-end;
      border-bottom-right-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      background-color: var(--color-white);
      border-top: 1px solid hsl(0deg, 0%, 86%);

      .footer__btn {
        font-weight: 400;
        &:not(:last-child) {
          margin-right: 0.5em;
        }
      }
    }
  }

  .switcher-wrapper {
    margin: 0 auto;
    padding: 20px 10px;
  }

  .list {
    width: 100%;
    margin-bottom: 7px;
  }

  .plan {
    display: flex;
    align-items: center;
    min-height: 80px;
    padding: 0 20px;
    cursor: pointer;
    transition: background-color 200ms linear;

    &:hover {
      background-color: #E7E7E7;
    }

    &--popular {
      background-color: #F0F0F0;
    }

    &__btn {
      margin-right: 20px;
    }

    &__img {
      width: 90px;
      margin-right: 18px;
      font-size: 0;

      &--documents {
        width: 40px;
      }
    }

    &__name {
      margin-right: auto;
      font-weight: 600;
    }
  }
  .radio-btn {
    position: relative;

    &__default {
      position: absolute;
      padding: 0;
      border: 0;
      height: 0;
      width: 0;
      opacity: 0;
    }

    &__custom {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border: 1px solid var(--current-color);
      border-radius: 100%;
      background-color: #fff;
    }

    &__default:checked + &__custom {
      border-width: 5px;
    }

  }

  .price {
    &__val {
      font-size: 1.5em;
      font-weight: bold;
    }

    &__period {
      font-size: 0.75em;
      line-height: 2;
    }
  }

  .total-price {
    padding: 0 20px;
    align-self: flex-end;
    font-weight: 600;

    &__loader {
      padding: 9px;
    }

    &__cell:not([align]) {
      padding-left: 16px;
      padding-top: 3px;
      text-align: end;
    }
  }
  .loader-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
    box-shadow: none;
    background-color: transparent;
  }

  .error-block {
    padding: 0 20px;
  }
</style>
